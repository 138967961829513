/* Main container */
.LandingPage {
  text-align: center;
  font-family: 'Open Sans', sans-serif;
  background-color: #f8f9fa; /* Light background for contrast */
  min-height: 100vh; /* Ensure the background fills the page */
  display: flex;
  flex-direction: column;
}

/* Header section with gradient background */
.LandingPage-header {
  background: linear-gradient(135deg, #0f2027, #203a43, #2c5364);
  flex: 1; /* Allow the header to grow and fill available space */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px; /* Reduced padding to move content higher */
  color: white;
}

/* Main title styling */
.LandingPage-title {
  font-family: 'Merriweather', serif;
  font-size: 5em; /* Increased font size */
  margin-bottom: 20px; /* Increased margin to add spacing between title and subtitle */
  letter-spacing: 1.5px;
  color: #ffffff;
  text-shadow: 3px 3px 6px rgba(0, 0, 0, 0.5);
  margin-top: -20px; /* Negative margin to move title higher */
}

/* Subheading below the title */
.LandingPage-subtitle {
  font-family: 'Open Sans', sans-serif;
  font-size: 2.2em; /* Increased font size */
  color: #ff7f50; /* Accent color for visibility */
  margin-bottom: 20px; /* Reduced margin to decrease spacing between subtitle and hero image */
  text-transform: uppercase;
  margin-top: -10px; /* Negative margin to move subtitle higher */
}

/* Hero image styling */
.LandingPage-hero {
  width: 100%;
  max-width: 800px;
  height: auto;
  margin: 15px 0; /* Reduced margin to decrease spacing between subtitle and hero image */
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.4);
  border-radius: 20px;
  overflow: hidden;
}

.LandingPage-hero img {
  width: 100%;
  border-radius: 20px;
  object-fit: cover;
}

/* Buttons */
.LandingPage-buttons {
  margin-top: 10px; /* Further reduced margin to decrease spacing between hero image and buttons */
}

.LandingPage-link {
  color: white;
  background-color: #ff7f50;
  padding: 15px 40px;
  margin: 10px;
  border-radius: 8px;
  text-decoration: none;
  font-size: 1.4em;
  font-weight: bold;
  text-transform: uppercase;
  transition: background-color 0.3s ease, transform 0.2s ease;
  display: inline-block;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.3);
}

.LandingPage-link:hover {
  background-color: #ff5722;
  transform: translateY(-5px);
}

/* Footer styling */
.LandingPage-footer {
  background-color: #203a43;
  color: white;
  text-align: center;
  padding: 15px; /* Reduced padding to make the footer shorter */
  margin-top: auto; /* Push the footer to the bottom */
  width: 100%; /* Ensure the footer spans the entire width */
  box-sizing: border-box; /* Include padding in the element's total width and height */
}

/* Responsive design */
@media (max-width: 768px) {
  .LandingPage-title {
    font-size: 3em; /* Adjusted for smaller screens */
  }

  .LandingPage-subtitle {
    font-size: 1.5em; /* Adjusted for smaller screens */
  }

  .LandingPage-link {
    font-size: 1em;
    padding: 10px 20px;
  }
}

/* Portrait orientation */
@media (orientation: portrait) {
  .LandingPage-header {
    padding: 20px; /* Ensure consistent padding in portrait mode */
  }

  .LandingPage-title {
    font-size: 4em; /* Adjusted font size for portrait mode */
    margin-top: -5px; /* Reduced negative margin for portrait mode */
  }

  .LandingPage-subtitle {
    font-size: 2em; /* Adjusted font size for portrait mode */
    margin-top: 0; /* Reduced negative margin for portrait mode */
    margin-bottom: 15px; /* Adjusted margin to decrease spacing between subtitle and hero image */
  }

  .LandingPage-hero {
    margin: 10px 0; /* Further reduced margin to decrease spacing between subtitle and hero image */
  }

  .LandingPage-link {
    font-size: 1.4em; /* Slightly reduced font size for buttons in portrait mode */
    padding: 15px 40px; /* Slightly reduced padding for buttons in portrait mode */
  }

  .LandingPage-buttons {
    margin-top: 15px; /* Further reduced margin to decrease spacing between hero image and buttons */
  }
}
